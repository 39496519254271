import React from "react"
import { Link } from "gatsby"
import { Grid, Typography, Container, Button } from "@mui/material"

import ReactPlayer from "react-player"
import { StaticImage } from "gatsby-plugin-image"

import { Center, FancyHr } from "../Layout"
import { AnimateOnScroll } from "../AnimateOnScroll"

import { Segment } from "../Segment"
import { Section } from "../Section"

const BouleScholars = () => {
  return (
    <>
      <Segment>
        <AnimateOnScroll animateIn="fadeIn" animateOut="fadeIn">
          <Container>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="h1" color="primary" align="center">
                  Boule' Scholars Program
                </Typography>
                <Typography variant="h5" color="secondary" align="center">
                  Cultivating and Celebrating Excellence
                </Typography>
              </Grid>
            </Grid>
            <Grid container mb={4}>
              <Grid item xs={12}>
                <Center>
                  <StaticImage
                    src="../../images/logo-boule-scholars.png"
                    alt="elb logo"
                    placeholder="blurred"
                    width={550}
                  />
                </Center>
              </Grid>
            </Grid>
            <Grid container mb={4}>
              <Grid item xs={12}>
                <Typography paragraph align="center">
                  If you ever dreamed of getting your teenage son or student
                  connected with some of Palm Beach County's top business and
                  civic leaders here is your chance. Boule Scholars is a
                  mentoring program that is not only a dream come true, your
                  students dream is exactly what can happen.. Whatever your
                  young adult's dream is, Boule Scholars and our vast database
                  of mentors are here to make sure your student is supported by
                  men who have been there and done that. From corporate business
                  leaders, entrepreneurs to medical doctors and even
                  professional sports. Our roster of mentors have your young
                  student covered no matter how large the dream.
                </Typography>
                <Typography paragraph align="center">
                  Boule Scholars registration has officially opened for the
                  2022-2223 school year. The Boule Scholar program is accepting
                  registrations from Palm Beach County students only, we have 15
                  spots available for 1tth & 12th graders with at least a 2.5
                  GPA and must be college bound. Each candidate will be granted
                  a formal interview and will be chosen based on their potential
                  and own merits. All your students needs to bring is hunger and
                  desire, The Boule Scholars will do the rest.
                </Typography>
                <Typography align="center">
                  Registration will close{" "}
                  <strong>October 14, 2022 at 5PM</strong>.
                </Typography>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Center>
                <Button
                  href="https://forms.gle/2qTxzW7Z2CRFE5zW9"
                  target="_blank"
                  rel="noopener"
                  variant="contained"
                  size="large"
                  color="alternate"
                >
                  Apply to Become a Boulé Scholar
                </Button>
              </Center>
            </Grid>
          </Container>
        </AnimateOnScroll>
      </Segment>
      <Segment variant="primary">
        <AnimateOnScroll animateIn="fadeIn" animateOut="fadeIn">
          <Section mb={4}>
            <Container>
              <Grid container>
                <Grid item xs={12}>
                  <Typography variant="h4" color="common.white" align="center">
                    Administered by the Archons
                  </Typography>
                  <FancyHr alt />
                  <Typography color="common.white" align="center">
                    The Boule Scholars program as administered by the archons
                    (members) of the Epsilon Lambda Member Boule (Palm Beach,
                    Martin and St. Lucie Counties, Florida) of Sigma Pi Phi
                    Fraternity, Inc. (“the Fraternity” or “the Boule”) focuses
                    on the total development of male high school-age children in
                    the Palm Beach County, Florida area.
                  </Typography>
                </Grid>
              </Grid>
            </Container>
          </Section>
          <Section>
            <Grid container spacing={2}>
              <Grid item xs={12} md={3}>
                <Center>
                  <StaticImage
                    src="../../images/boule/img-keynote-1.jpg"
                    alt="elb logo"
                    placeholder="blurred"
                    width={390}
                  />
                </Center>
              </Grid>
              <Grid item xs={12} md={3}>
                <Center>
                  <StaticImage
                    src="../../images/boule/img-keynote-2.jpg"
                    alt="elb logo"
                    placeholder="blurred"
                    width={390}
                  />
                </Center>
              </Grid>
              <Grid item xs={12} md={3}>
                {" "}
                <Center>
                  <StaticImage
                    src="../../images/boule/img-keynote-3.jpg"
                    alt="elb logo"
                    placeholder="blurred"
                    width={390}
                  />
                </Center>
              </Grid>
              <Grid item xs={12} md={3}>
                {" "}
                <Center>
                  <StaticImage
                    src="../../images/boule/img-keynote-4.jpg"
                    alt="elb logo"
                    placeholder="blurred"
                    width={390}
                  />
                </Center>
              </Grid>
            </Grid>
          </Section>
        </AnimateOnScroll>
      </Segment>
      <Segment>
        <AnimateOnScroll animateIn="fadeIn" animateOut="fadeIn">
          <Container>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="h4" color="primary" align="center">
                  History
                </Typography>
                <FancyHr />
                <Typography align="center">
                  Since its inception in 2012, the ELB Scholars program has been
                  a rousing success. The program has totaled over seventy-five
                  (75) participants during that time. One hundred percent of the
                  participants have graduated from high school and all have gone
                  on to pursue higher education. An estimated eighty percent of
                  the young men have matriculated at Historically Black Colleges
                  or Universities (“HBCUs”). The HBCU element is important
                  because so many of the archon instructors studied at HBCUs and
                  often discuss the myriad opportunities and nurturing
                  environment they provide with the participants. The Epsilon
                  Lambda Boule Scholars program has been recognized nationally
                  by the Fraternity for its outstanding work, as well.
                </Typography>
              </Grid>
            </Grid>
          </Container>
        </AnimateOnScroll>
      </Segment>
      <Segment variant="primary">
        <AnimateOnScroll animateIn="fadeIn" animateOut="fadeIn">
          <Section mb={4}>
            <Container>
              <Grid container>
                <Grid item xs={12}>
                  <Typography variant="h4" color="common.white" align="center">
                    The Program
                  </Typography>
                  <FancyHr alt />
                  <Typography color="common.white" align="center">
                    Participants are selected from area high schools based on a
                    number of criteria including demonstrated community service
                    activities, a firm commitment to pursue higher education,
                    participation in one or more National Urban League youth
                    programs and a minimum G.P.A. of 2.5. Once selected, the
                    students work on an individual or small group basis during
                    the program -- which runs for a period roughly equivalent to
                    that of the school year -- to develop an idea or product
                    that is of interest to them. To further this goal, the young
                    men are provided college and professional level instruction
                    from the archons whose fields of endeavor vary widely from
                    marketing and communication to law, medicine, and finance.
                    These learning sessions are held on a monthly basis and
                    focus on one or two areas of study per session. For example,
                    students might spend one session focused on the intellectual
                    property protection aspects of bringing a new product to
                    market, or the mechanics of focus group testing to determine
                    consumer interest in the product. The program culminates in
                    the students presenting their projects to their family and
                    friends in a friendly competition format judged by the
                    archons and other community members. The young men are
                    always proud of what they have accomplished at the end of
                    the program, and the projects are impressive.
                  </Typography>
                </Grid>
              </Grid>
            </Container>
          </Section>
        </AnimateOnScroll>
      </Segment>
      <Segment>
        <AnimateOnScroll animateIn="fadeIn" animateOut="fadeIn">
          <Container>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="h4" color="primary" align="center">
                  Financial Literacy and Wealth Acquisition
                </Typography>
                <FancyHr />
                <Typography align="center">
                  Truth, courage, and strong moral character are codes that
                  provide the foundation towards academic excellence and higher
                  education while obtaining financial literacy and wealth
                  acquisition in order to contribute to the greater good of our
                  nation.{" "}
                </Typography>
              </Grid>
            </Grid>
          </Container>
        </AnimateOnScroll>
      </Segment>
      <Segment variant="primary">
        <AnimateOnScroll animateIn="fadeIn" animateOut="fadeIn">
          <Section mb={4}>
            <Container>
              <Grid container>
                <Grid item xs={12}>
                  <Typography variant="h4" color="common.white" align="center">
                    Testimonials
                  </Typography>
                  <FancyHr alt />
                  <Typography variant="h5" color="alternat.main" align="center">
                    "We had a project about finding a problem in the community
                    and coming up with a solution"
                  </Typography>
                </Grid>
              </Grid>
            </Container>
          </Section>
          <Section>
            <Grid container spacing={4}>
              <Grid item xs={12} md={6}>
                <Center>
                  <ReactPlayer
                    url="https://www.youtube.com/embed/QtytiWT92Go"
                    controls="true"
                    className="video-wrapper"
                  />
                </Center>
              </Grid>
              <Grid item xs={12} md={6}>
                {" "}
                <Center>
                  <ReactPlayer
                    url="https://www.youtube.com/embed/6TQ5w3tB2_Y"
                    controls="true"
                    className="video-wrapper"
                  />
                </Center>
              </Grid>
              <Grid item xs={12} md={6}>
                {" "}
                <Center>
                  <ReactPlayer
                    url="https://www.youtube.com/embed/_4UF3G9RrTk"
                    controls="true"
                    className="video-wrapper"
                  />
                </Center>
              </Grid>
              <Grid item xs={12} md={6}>
                {" "}
                <Center>
                  <ReactPlayer
                    url="https://www.youtube.com/embed/Rj_ePfptTTo"
                    controls="true"
                    className="video-wrapper"
                  />
                </Center>
              </Grid>
              <Grid item xs={12} md={6}>
                {" "}
                <Center>
                  <ReactPlayer
                    url="https://www.youtube.com/embed/vYk9VssNtls"
                    controls="true"
                    className="video-wrapper"
                  />
                </Center>
              </Grid>
              <Grid item xs={12} md={6}>
                {" "}
                <Center>
                  <ReactPlayer
                    url="https://www.youtube.com/embed/xd9istjnMd4"
                    controls="true"
                    className="video-wrapper"
                  />
                </Center>
              </Grid>
            </Grid>
          </Section>
        </AnimateOnScroll>
      </Segment>
      <Segment>
        <AnimateOnScroll animateIn="fadeIn" animateOut="fadeIn">
          <Container>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="h4" color="primary" align="center">
                  Boule' Scholar Events
                </Typography>
                <FancyHr />
              </Grid>
            </Grid>
            <Section>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Center>
                    <StaticImage
                      src="../../images/boule/img-events-1.jpg"
                      alt="elb logo"
                      placeholder="blurred"
                      width={390}
                    />
                  </Center>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Center>
                    <StaticImage
                      src="../../images/boule/img-events-2.jpg"
                      alt="elb logo"
                      placeholder="blurred"
                      width={390}
                    />
                  </Center>
                </Grid>
              </Grid>
            </Section>
          </Container>
        </AnimateOnScroll>
      </Segment>
    </>
  )
}
export default BouleScholars
